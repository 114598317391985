import qs from 'qs';

import { HomeModule } from '@/modules';

// const Home = ({ blogList }: HomeProps) => <HomeModule blogList={blogList} />;
const Home = () => <HomeModule />;

export async function getStaticProps() {
  const queryBlogList = qs.stringify({
    populate: '*',
    sort: {
      publishedAt: 'desc',
    },
    pagination: {
      start: 0,
      limit: 3,
    },
  });

  const { data: blogList } = await (
    await fetch(`${process.env.NEXT_PUBLIC_STRAPI_URL}/api/blogs?${queryBlogList}`)
  ).json();

  return {
    props: {
      blogList: blogList.length > 0 ? blogList : null,
    },
    revalidate: 5,
  };
}

export default Home;
